import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Card from './Card';
import DataFetcher from './DataFetcher';
import Menu from './Menu';
import { AppContext, AppContextProvider } from './AppContext';
import CacheBuster from './CacheBuster';
import AuthState from './AuthState';
import * as S from '../styles';
import LogOut from './LogOut';
import ErrorView from './ErrorView';
const QrReader = React.lazy(() => import('./QrReader'));

const App: React.FC = () => (
  <S.Wrapper>
    <BrowserRouter>
      <AppContextProvider>
        <CacheBuster />
        <S.GlobalStyle />
        <Navbar />
        <Routes>
          <Route path="/:page?/:cardId?" element={<DataFetcher />} />
        </Routes>
        <Routes>
          <Route path="/:page?/:cardToken?" element={<AuthState />} />
        </Routes>
        <AppContext.Consumer>
          {({ isMenuActive, card }) =>
            isMenuActive ? (
              <Menu />
            ) : (
              <Routes>
                <Route path="/" element={card ? <Navigate to={`/cards/${card.id}`} /> : <Card />} />
                <Route path="/cards/:cardId" element={<Card />} />
                <Route path="/qr-reader" element={<QrReader />} />
                <Route path="/logout" element={<LogOut />} />
                <Route path="/error" element={<ErrorView />} />
              </Routes>
            )
          }
        </AppContext.Consumer>
      </AppContextProvider>
    </BrowserRouter>
  </S.Wrapper>
);

export default App;

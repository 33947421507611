import React from 'react';
import { MemberData } from '../types';
import getClubName from '../utils/getClubName';
import * as S from '../styles';

type Props = {
  memberData: MemberData;
};

const MemberInfo: React.FC<Props> = ({ memberData }) => (
  <S.Info>
    <div data-test-member-id="">{memberData.memberId}</div>
    <div data-test-alias="">{memberData.alias}</div>
    <div data-test-expiry="">{memberData.expiry}</div>
    <div data-test-club="">{getClubName(memberData.club)}</div>
  </S.Info>
);

export default MemberInfo;

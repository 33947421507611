import React, { useState } from 'react';
import { useAppContextValue } from './AppContext';
import getClubName from '../utils/getClubName';
import * as S from '../styles';
import signOut from '../utils/signOut';

const Menu: React.FC = () => {
  const { user, card, syncTimestamp, version } = useAppContextValue();
  const [displaySyncTimestamp, setDisplaySyncTimestamp] = useState(true);

  return (
    <S.Menu>
      <S.MenuQrLink>
        <S.MenuLink to="/qr-reader">Les av QR-koder</S.MenuLink>
      </S.MenuQrLink>
      {user ? (
        <S.CardChooser>
          <section>
            <strong>Ditt kort:</strong>
          </section>
          {card ? (
            <S.MenuLink key={card.id} to={`/cards/${card.id}`}>
              {getClubName(card.club)}
            </S.MenuLink>
          ) : (
            <span>
              <em>Du har ingen kort</em>
            </span>
          )}
        </S.CardChooser>
      ) : (
        <S.CardChooser />
      )}

      <S.MenuFooter>
        {user ? (
          <>
            {/*<S.MenuLink data-test-change-password-link="" to="/change-password">*/}
            {/*  Bytt passord*/}
            {/*</S.MenuLink>*/}
            <S.MenuLink
              data-test-log-out-link=""
              to="/logout"
              onClick={(event) => {
                event.stopPropagation();
                signOut();
              }}>
              Logg ut
            </S.MenuLink>
          </>
        ) : (
          // <S.MenuLink data-test-login-link="" to="/login">
          //   Logg inn
          // </S.MenuLink>
          <>{/* Login should be there, in some form. Perhaps enter a token or something. */}</>
        )}
        {!window.Worker && (
          <S.MenuWarning>
            <span>⚠️</span>️
            <span>Nettleseren din støtter ikke offline-funksjonalitet, du må ha nett for å bruke Skog & Mark</span>
            <span>⚠️</span>
          </S.MenuWarning>
        )}
        <div onClick={() => setDisplaySyncTimestamp(!displaySyncTimestamp)}>
          {displaySyncTimestamp ? (
            <>
              <span>
                <em>Sist synkronisert:</em>
              </span>
              <span data-test-last-synchronized="">
                <em>{syncTimestamp ? syncTimestamp.toLocaleString() : 'Aldri'}</em>
              </span>
            </>
          ) : (
            <>
              <span>
                <em>Versjon:</em>
              </span>
              <span data-test-version="">
                <em>{process.env.NODE_ENV === 'production' ? version : 'Utvikling'}</em>
              </span>
            </>
          )}
        </div>
      </S.MenuFooter>
    </S.Menu>
  );
};

export default Menu;

import React, { useEffect, useRef, useState } from 'react';
import { useAppContextValue } from './AppContext';
import * as S from '../styles';

const Spinner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef<number | undefined>();
  const { isLoggingIn, isFetchingData, isSubmittingForm, user, card } = useAppContextValue();
  const isLoading = isLoggingIn || isFetchingData || isSubmittingForm || (user && !card);

  useEffect(() => {
    if (isLoading) {
      setIsVisible(true);
    } else {
      timeoutRef.current = setTimeout(() => setIsVisible(false), 500) as unknown as number;
    }
    return () => clearTimeout(timeoutRef.current);
  }, [isLoading, setIsVisible]);

  return <S.Spinner isVisible={isVisible} />;
};

export default Spinner;

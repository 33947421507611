import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import signOut from '../utils/signOut';

const LogOut: React.FC = () => {
  useEffect(() => {
    signOut();
  }, []);
  return <Navigate to="/login" />;
};

export default LogOut;

import React, { useEffect } from 'react';
import packageJson from '../../package.json';
import { useAppContextValue } from './AppContext';
const environment = process.env.NODE_ENV;
const buildTimestamp = process.env.REACT_APP_BUILD_TIMESTAMP;

const CacheBuster: React.FC = () => {
  const { updateState } = useAppContextValue();
  const { version } = packageJson;
  useEffect(() => {
    const versionString = `${version}${buildTimestamp ? ` (${buildTimestamp})` : ''}`;
    console.info(`Skog & Mark version ${versionString}, environment ${environment}`);
    if (environment === 'production') {
      updateState({ version: versionString });
      fetch(`/meta.json?_=${new Date().getTime()}`)
        .then((response) => response.json())
        .then(async (meta) => {
          if ((buildTimestamp && buildTimestamp !== meta.buildTimestamp) || meta.version !== version) {
            try {
              await navigator.serviceWorker
                ?.getRegistrations()
                .then((registrations) => Promise.all(registrations.map((registration) => registration.unregister())));
              window.location.reload();
            } catch (error) {
              console.error(error);
            }
          }
        })
        .catch(() => console.info('Fetching meta.json failed, continuing with currently cached version of app.'));
    }
  }, [updateState, version]);
  return null;
};

export default CacheBuster;

import jwt from 'jsonwebtoken';
import { Card } from '../types';

const setCardToken = (cardToken: string) => {
  clearCardTokens();
  const { club, memberId } = jwt.decode(cardToken) as Card;
  const cardId = `${club}-${memberId}`;
  localStorage.setItem(`card-token-${cardId}`, cardToken);
  return cardId;
};

const getCardToken = (cardId?: string) => cardId && localStorage.getItem(`card-token-${cardId}`);

const clearCardTokens = () => {
  for (let index = 0; index < localStorage.length; index++) {
    const key = localStorage.key(index);
    if (key?.startsWith('card-token-')) {
      localStorage.removeItem(key);
    }
  }
};

const localStorageUtils = {
  setCardToken,
  getCardToken,
  clearCardTokens,
};

export default localStorageUtils;

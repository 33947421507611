import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext as AppContextType, Card } from '../types';
import { useLocation } from 'react-router-dom';

// "undefined!" is a way to get around providing a default implementation of all setters,
// since consumers will be inside the provider and will always get an AppContextType object.
export const AppContext = React.createContext<
  AppContextType & { updateState: (partialState: Partial<AppContextType>) => void }
>(undefined!);

export const AppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const [state, setState] = useState<AppContextType>({
    user: undefined,
    isFetchingData: false,
    isSubmittingForm: false,
    fetchDataError: undefined,
    isLoggingIn: false,
    isMenuActive: false,
    card: undefined,
    syncTimestamp: undefined,
    version: '',
  });

  const updateState = useCallback(
    (partialState: Partial<AppContextType>) => setState((state) => ({ ...state, ...partialState })),
    [setState]
  );

  useEffect(() => {
    (async () => getDataFromCache().then((data) => updateState(data)))();
  }, [updateState]);

  useEffect(() => {
    updateState({ isMenuActive: false });
  }, [updateState, location]);

  return <AppContext.Provider value={{ ...state, updateState }}>{children}</AppContext.Provider>;
};

const getDataFromCache = () =>
  caches
    .open('cards')
    .then((cache) =>
      cache.match(`${process.env.REACT_APP_CLOUD_FUNCTIONS_HOST}/api/cards`).then(async (response) => ({
        card: (await response!.json()) as Card,
        syncTimestamp: new Date(response!.headers.get('date')!),
      }))
    )
    .catch(() => ({}));

export const useAppContextValue = () => useContext(AppContext);

import React from 'react';
import { useQueryParams } from '../hooks/useQueryParams';
import * as S from '../styles';

const ErrorView = () => {
  const query = useQueryParams();
  const code = query.get('code');

  let content;
  if (code === 'login/outdated-token') {
    content = <p>Lenken du har brukt til innlogging er utdatert. Skog & Mark har derfor sendt en ny til din epost.</p>;
  } else if (code === 'cards/outdated-auth') {
    content = <p>Kunne ikke hente kort pga. utdatert innlogging. Skog & Mark har derfor sendt en ny til din epost.</p>;
  } else if (code === 'network') {
    content = <p>Nettverksfeil, vennligst prøv igjen.</p>;
  } else {
    return <>Ukjent feil.</>;
  }

  return <S.ErrorView>{content}</S.ErrorView>;
};

export default ErrorView;

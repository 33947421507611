import React, { useCallback, useEffect } from 'react';
import { useAppContextValue } from './AppContext';
import { Card } from '../types';
import jwt from 'jsonwebtoken';
import { useNavigate, useParams } from 'react-router-dom';
import localStorageUtils from '../utils/localStorageUtils';

const DataFetcher: React.FC = () => {
  const { cardId } = useParams();
  const cardToken = localStorageUtils.getCardToken(cardId);
  const { updateState, user } = useAppContextValue();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (!user) {
      return;
    }
    if (cardToken) {
      updateState({ isFetchingData: true });
      await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS_HOST}/api/cards/${cardToken}`, {
        headers: {
          authorization: `Bearer ${await user.getIdToken().catch((error) => {
            // If network is down, just eat the exception and let fetch continue, and hopefully retrieve the cards from cache.
            if (error.code !== 'auth/network-request-failed') {
              throw Error;
            }
          })}`,
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            const { club, memberId, expiry, alias } = jwt.decode(cardToken) as Card; // FIXME - Find better way to propagate this data. Also, make sure to clear local storage when logging out.
            updateState({
              syncTimestamp: new Date(response.headers.get('date')!),
              card: { ...(await response.json()), club, memberId, expiry, alias, cardToken },
              fetchDataError: undefined,
            });
            return;
          }
          throw await response.json();
        })
        .catch((error) => {
          if (error.code === 'cards/outdated-auth') {
            navigate(`/error?code=${error.code}`);
          } else if (error.code) {
            updateState({ fetchDataError: 'unknown' });
          } else {
            updateState({ fetchDataError: 'network' });
          }
        });
      updateState({ isFetchingData: false });
    }
  }, [user, updateState, cardToken, navigate]);

  useEffect(() => {
    (async () => fetchData())();
  }, [fetchData]);

  useEffect(() => {
    const callback = () => fetchData();
    window.addEventListener('focus', callback);
    return () => window.removeEventListener('focus', callback);
  }, [fetchData]);

  return null;
};

export default DataFetcher;

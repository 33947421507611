import React, { useEffect, useRef } from 'react';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContextValue } from './AppContext';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import localStorageUtils from '../utils/localStorageUtils';

const AuthState: React.FC = () => {
  const { page, cardToken } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggingIn, updateState } = useAppContextValue();
  const didMountRef = useRef(false);

  useEffect(() => {
    const stopListener = getAuth().onAuthStateChanged((user) => {
      if (!isLoggingIn && cardToken && page === 'login') {
        updateState({ isLoggingIn: true });
        return fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS_HOST}/api/login`, {
          method: 'POST',
          body: JSON.stringify({ cardToken }),
          headers: { 'content-type': 'application/json' },
        })
          .then(async (response) => {
            if (response.status === 200) {
              const { customToken, cardToken } = await response.json();
              return signInWithCustomToken(getAuth(), customToken).then(() => {
                const cardId = localStorageUtils.setCardToken(cardToken);
                navigate(`/cards/${cardId}`);
              });
            }
            throw await response.json();
          })
          .catch((error) => {
            if (error.code) {
              navigate(`/error?code=${error.code}`);
            } else {
              navigate(`/error?code=network`);
            }
          })
          .finally(() => updateState({ isLoggingIn: false }));
      }

      if (user) {
        // User is signed in.
        updateState({ user });
      } else {
        updateState({ card: undefined, syncTimestamp: undefined, user: undefined });
        localStorageUtils.clearCardTokens();
        if (
          didMountRef.current &&
          (matchPath(location.pathname, '/cards/:cardId') || matchPath(location.pathname, '/'))
        ) {
          navigate('/login');
        }
      }
    });
    didMountRef.current = true;
    return () => stopListener();
  }, [navigate, location, isLoggingIn, updateState, cardToken, page]);
  return null;
};

export default AuthState;

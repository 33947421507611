import React from 'react';
import * as S from '../styles';

type Props = React.PropsWithChildren<{
  onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}>;

const Button: React.FC<Props> = ({ onClick, disabled, type, children, ...rest }) => (
  <S.Button onClick={onClick} disabled={disabled} type={type} {...rest}>
    {children}
  </S.Button>
);

export default Button;

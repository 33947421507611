import React from 'react';
import MemberInfo from './MemberInfo';
import { useAppContextValue } from './AppContext';
import * as S from '../styles';
import Button from './Button';
import signOut from '../utils/signOut';
import { Navigate, useParams } from 'react-router-dom';

const Card: React.FC = () => {
  const { cardId } = useParams();
  const { card, isFetchingData, isLoggingIn, fetchDataError } = useAppContextValue();

  if (!card && isLoggingIn) {
    return <S.Message>Laster bruker...</S.Message>;
  } else if (!card && isFetchingData) {
    return <S.Message>Laster kort...</S.Message>;
  } else if (!card) {
    return <S.Message>Du har ingen medlemskort</S.Message>;
  } else if (card && card.id !== cardId) {
    return <Navigate to={`/cards/${card.id}`} />;
  } else if (!card && fetchDataError) {
    return (
      <S.CardError>
        <div>
          <span>Noe gikk galt ved henting av kort</span>
          <Button
            onClick={() => {
              // DataFetcher will refetch on focus
              // This is a hack while we contemplate using react-query, which solves this
              window.dispatchEvent(new Event('focus'));
            }}>
            Hent på nytt
          </Button>
        </div>
        <div>
          <span>Du kan også prøve å logge inn på nytt</span>
          <Button onClick={() => signOut()}>Logg ut</Button>
        </div>
      </S.CardError>
    );
  }

  return (
    <S.Card>
      <S.Container>
        <S.ImageContainer>
          <S.QrImage src={card.qr} alt="QR code" />
        </S.ImageContainer>
      </S.Container>
      <MemberInfo memberData={card} />
    </S.Card>
  );
};

export default Card;

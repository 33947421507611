export const kinkRed = '#900';
export const kinkRedLightish = '#800';
export const kinkRedDarker = '#700';
export const kinkRedDarkest = '#500';

export const gray1 = '#555555';
export const gray3 = '#333333';

export const fontSizes = {
  normal: '1rem',
  large: '1.25rem',
  extraLarge: '1.5rem',
  extraExtraLarge: '2rem',
};

export const lineHeights = {
  medium: '1.35',
  large: '1.50',
  extraLarge: '1.75',
  small: '1.15',
};

import { createGlobalStyle } from 'styled-components/macro';
import { fontSizes, gray1, gray3, kinkRed, kinkRedDarker, kinkRedDarkest, kinkRedLightish } from './stylingVariables';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  h1 {
    margin-top: 0;
  }
  a {
    margin-top: 16px;
  }
`;

export const ErrorView = styled.div`
  display: grid;
  place-items: center;
  color: white;
  width: 100%;
  height: 100%;
  padding: 64px;
  p {
    text-align: center;
    font-size: ${fontSizes.large};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: ${fontSizes.large};
  color: white;

  input::placeholder {
    color: ${gray1};
  }

  input {
    border: 2px solid ${gray3};
    border-radius: 0;
    color: white;
    background-color: ${gray3};
    height: 40px;
    margin-bottom: 16px;
    font-size: ${fontSizes.large};
  }

  input:focus {
    border: 2px solid ${kinkRed};
    outline: none;
  }

  h1 {
    color: ${kinkRed};
    font-size: ${fontSizes.extraExtraLarge};
    margin-bottom: 16px;
  }

  button {
    margin-top: 16px;
    font-size: ${fontSizes.large};
  }
`;

export const Label = styled.label`
  color: white;
  width: 100%;
  margin-bottom: 8px;
`;

export const FormError = styled.p`
  color: red;
  margin-bottom: 8px;
  font-size: ${fontSizes.large};
`;

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Link = styled(RouterLink)`
  color: white;
  font-size: ${fontSizes.large};
`;

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    width: 100%;
    height: 100%;
    font-size: 100%;
  }
  body {
    margin: 0;
    overflow: auto;
    background-color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
  }
  #root {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 900px;
  @media (min-width: 502px) {
    border-left: 1px solid ${gray1};
    border-right: 1px solid ${gray1};
  }
  @media (min-width: 504px) {
    border-left: 2px solid ${gray1};
    border-right: 2px solid ${gray1};
  }
  @media (min-height: 902px) {
    border-top: 1px solid ${gray1};
    border-bottom: 1px solid ${gray1};
  }
  @media (min-height: 904px) {
    border-top: 2px solid ${gray1};
    border-bottom: 2px solid ${gray1};
  }
`;

export const Button = styled.button`
  padding: 10px;
  color: white;
  border: none;
  background-color: ${kinkRedDarker};
  cursor: pointer;
  text-decoration: none;
  :hover {
    background-color: ${kinkRedLightish};
  }
  :active {
    background-color: ${kinkRedDarkest};
  }
  :disabled {
    background-color: ${gray3};
    color: ${gray1};
    pointer-events: none;
  }
`;

export const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const QrImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const Message = styled.div`
  flex: 1;
  display: grid;
  place-items: center;
  color: white;
  font-size: ${fontSizes.large};
  padding: 10px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  @media only screen and (max-height: 550px) and (max-width: 360px) {
    width: 90%;
    padding-top: 90%; /* 1:1 Aspect Ratio */
  }
  @media only screen and (max-height: 500px) and (max-width: 360px) {
    width: 80%;
    padding-top: 80%; /* 1:1 Aspect Ratio */
  }
  @media only screen and (max-height: 500px) and (max-width: 320px) {
    width: 90%;
    padding-top: 90%; /* 1:1 Aspect Ratio */
  }
  @media only screen and (max-height: 460px) and (max-width: 320px) {
    width: 80%;
    padding-top: 80%; /* 1:1 Aspect Ratio */
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Hamburger = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  flex-grow: 100;
  @media only screen and (max-height: 560px) and (max-width: 330px) {
    //font-size: ${fontSizes.large};
  }
  font-size: ${fontSizes.extraLarge};
  color: white;
`;

export const MenuFooter = styled.div`
  & > * {
    margin-bottom: 16px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes.large};
  }
`;
export const MenuQrLink = styled.div``;
export const CardChooser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const CardError = styled.div`
  flex: 1;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  margin: 32px;
  font-size: ${fontSizes.large};
  justify-content: space-around;
  span {
    margin-bottom: 16px;
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  button {
    font-size: ${fontSizes.large};
  }
`;

export const Menu = styled.div`
  flex: 1;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  margin: 32px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes.large};
  }
  ${CardChooser} {
    flex: 1;
  }
`;

export const MenuWarning = styled.div`
  display: flex;
  flex-direction: row !important;
  text-align: center;
  flex-grow: unset;
  & span:first-child {
    font-family: 'Segoe UI Emoji';
    margin-right: 16px;
  }
  & span:last-child {
    font-family: 'Segoe UI Emoji';
    margin-left: 16px;
  }
`;

export const MenuLink = styled(Link)`
  color: white;
`;

export const Navbar = styled.div`
  background-color: black;
  height: 62px;
  width: 100%;
  display: flex;
  color: white;
  align-items: center;
  border-bottom: 2px solid #222222;
  position: relative;
`;

export const HamburgerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
`;

export const NavbarTitle = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bolder;
  font-size: ${fontSizes.large};
`;

export const QrReader = styled.div`
  width: 100%;
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const Error = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CanvasWrapper = styled.div<{ canvasWidth: number }>`
  min-height: ${(props) => (props.canvasWidth * 3) / 4}px;
  max-height: ${(props) => (props.canvasWidth * 5) / 6}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
`;

export const Spinner = styled.div<{ isVisible?: boolean }>`
  transition: 160ms;
  border: 8px solid ${gray3};
  border-top: 8px solid white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  animation-play-state: ${(props) => (props.isVisible ? 'running' : 'paused')};
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Status = styled.div<{ statusCode?: string }>`
  background-color: ${(props) => (props.statusCode ? (props.statusCode === 'valid' ? 'green' : 'red') : 'yellow')};
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: ${fontSizes.large};
`;
